import { TranslationSchema } from "./translation.interface";

export const no: TranslationSchema = {
  currency: "{amount}kr",
  calendar: {
    noServiceDialogMessage: "Ingen tilbydere tilbyr for øyeblikket denne tjenesten, vennligst velg en annen.",
    selectAnotherService: "Velg en annen tjeneste",
    availableHoursLabel: "Tilgjengelige ",
    hoursOnDateLabel: "Timer på ",
    monday: "Mandag",
    tuesday: "Tirsdag",
    wednesday: "Onsdag",
    thursday: "Torsdag",
    friday: "Fredag",
    saturday: "Lørdag",
    sunday: "Søndag",
    january: "Januar",
    february: "Februar",
    march: "Mars",
    april: "April",
    may: "Mai",
    june: "Juni",
    july: "Juli",
    august: "August",
    september: "September",
    october: "Oktober",
    november: "November",
    december: "Desember",
  },
  chooseStaff: {
    anyStaffMember: "Hvilken Som Helst Tilgjengelig Ansatt",
    anyStaffMemberSubtitle: "Bestill med første tilgjengelige ansatt",
    nextAvailable: "Neste Tilgjengelig",
    currentlyUnavailable: "For Øyeblikket Ikke Tilgjengelig",
  },
  hourList: {
    noAvailableHours: "Ingen ledige timer for denne dagen",
    findFirstAvailableHour: "Finn første ledige time",
  },
  datePicker: {
    cancel: "Avbryt",
    ok: "OK",
  },
  bookingForm: {
    selectDepartment: "Velg avdeling",
    nameRequired: "Navn er påkrevd",
    addressRequired: "Adresse er påkrevd",
    postalCodeRequired: "Postnummer er påkrevd",
    emailRequired: "E-post er påkrevd",
    emailValid: "E-post må være gyldig",
    phoneRequired: "Telefonnummer er påkrevd",
    phoneValid: "Telefonnummer må være gyldig",
    passwordRequired: "Passord er påkrevd",
    passwordLength: "Passord må være minst 5 tegn",
    service: "Tjeneste",
    description: "Beskrivelse",
    time: "Tid",
    totalDuration: "Totalt tid",
    total: "Totalt",
    selectService: "Vennligst velg en tjeneste.",
    checkboxTooltipBoth: "Merk av boksene for å motta bekreftelse/påminnelse for avtalen",
    checkboxTooltipReminder: "Merk av boksen for å motta en påminnelse for avtalen",
    checkboxTooltipReceipt: "Merk av boksen for å motta bekreftelse for avtalen",
    emailReminder: "Jeg ønsker påminnelse på e-post",
    emailReceipt: "Jeg ønsker bekreftelse på e-post",
    smsReminder: "Jeg ønsker påminnelse på SMS",
    smsReceipt: "Jeg ønsker bekreftelse på SMS",
    whatsappReminder: "Jeg ønsker påminnelse på WhatsApp",
    whatsappReceipt: "Jeg ønsker bekreftelse på WhatsApp",
    newsletterSignup: "Meld meg på nyhetsbrevet",
    bookAppointment: "Bestill time",
    agreeWithConsent: "Jeg godtar avbestillings- og endringsreglene",
    addComment: "Legg til en kommentar",
    phoneLength: "Telefonnummer må være {length} siffer",
    invalidCountryCode: "Ugyldig landekode",
  },
  bookingStepper: {
    back: "Tilbake",
    selectService: "Velg Tjeneste",
    selectServices: "Velg Tjenester",
    selectStaff: "Velg Ansatt",
    selectTime: "Velg Tid",
    yourInformation: "Din Informasjon",
    confirm: "Bekreft",
    receipt: "Kvittering",
    thankYou: "Takk for bestillingen!",
    receiptEmail: "En kvittering er sendt til din e-post.",
    reminderEmail: "En påminnelse vil bli sendt til din e-post.",
    receiptSMS: "En kvittering er sendt til din telefon.",
    reminderSMS: "En påminnelse vil bli sendt til din telefon.",
    booking: "Bestilling",
  },
  chooseService: {
    selectedServices: "Valgte tjenester",
    continue: "Fortsett",
    searchServices: "Søk tjenester",
    searchPlaceholder: "Skriv for å søke etter tjenester...",
  },
  dialog: {
    description: "Beskrivelse",
    cancel: "Avbryt",
    confirm: "Bekreft",
    yes: "Ja",
    no: "Nei",
  },
  customerAccount: {
    phoneRequired: "Telefonnummer er påkrevd",
    phoneValid: "Telefonnummer må være gyldig",
    codeRequired: "Kode er påkrevd",
    codeValid: "Kode må være gyldig",
    myPage: "Min Side",
    invalidCode: "Ugyldig kode, prøv igjen",
    confirmCancel: "Er du sikker på at du vil avbestille denne timen?",
    appointmentCancelled: "Din time er avbestilt.",
    cancelLimit: "Du kan bare avbestille {hours} timer i forveien.",
    appointmentChanged: "Du har endret din time!",
    changeTime: "Vil du endre tiden til {date} {time}?",
    phoneNumber: "Ditt telefonnummer",
    smsCode: "Kode fra SMS",
    login: "Logg inn",
    yourAppointments: "Dine Avtaler",
    cancellationPolicy: "Avbestilling/Endring av avtale må gjøres minst {hours} timer før den planlagte tiden.",
    service: "Tjeneste",
    time: "Tid",
    move: "Flytt",
    cancel: "Avbestill",
    bookNewAppointment: "Bestill ny time",
    editInformation: "Rediger din informasjon",
    closeEditing: "Lukk redigering",
    editUser: "Rediger bruker",
    save: "Lagre",
    name: "Ditt navn",
    phoneNumberOptional: "Telefonnummer (Valgfritt)",
    email: "E-post",
    emailOptional: "E-post (Valgfritt)",
    address: "Adresse",
    city: "By",
    postalCode: "Postnummer",
    ssn: "Personnummer",
    birthdate: "Fødselsdato",
    gender: "Kjønn",
    male: "Mann",
    female: "Kvinne",
    password: "Passord",
    sendNewPassword: "Send nytt passord",
    enterCodeBelow: "Skriv inn koden nedenfor",
    passwordChanged: "Ditt passord er endret",
    next: "Neste",
    cityRequired: "By er påkrevd",
    countryCode: "Landekode",
    countryCodeRequired: "Landekode er påkrevd",
    birthdateRequired: "Fødselsdato er påkrevd",
    genderRequired: "Kjønn er påkrevd",
    ssnRequired: "Personnummer er påkrevd",
  },
  serviceItem: {
    noAvailableTimes: "Ingen tilgjengelige tider for denne tjenesten.",
    today: "i dag",
    tomorrow: "i morgen",
    dayAfterTomorrow: "i overmorgen",
    selectAdditionalServices: "Velg tilleggstjenester",
    continue: "Fortsett",
    confirm: "Bekreft",
    firstAvailable: "Først tilgjengelig",
    hour: "time",
    hours: "timer",
    min: "min",
    variablePriceInfo: "Prisen varierer avhengig av tjenestens detaljer. Personalet vil gi nøyaktig pris ved ankomst.",
    unavailableOnlineBooking: "Denne tjenesten er ikke tilgjengelig for online bestilling",
    useWhatsApp: "Vennligst bruk WhatsApp for å bestille denne tjenesten",
    whatsAppMessage: "Hei! Jeg ønsker å bestille",
    whatsAppDateTime: "for",
  },
  mainApp: {
    myPage: "Min Side",
    logout: "Logg ut",
    leaveBooking: "Ønsker du å forlate bestillingen?",
  },
  receiptPage: {
    eventTitle: "Avtale hos {companyName}",
    addToCalendar: "Legg til i kalenderen din",
    bookNewTime: "Bestill ny time",
    goToMyPage: "Gå til min side",
  },
  payment: {
    title: "Betaling",
    description: "Beløp å betale: {amount} {currency}",
    noProviderConfigured: "Ingen betalingsleverandør er konfigurert. Vennligst kontakt tjenesteleverandøren.",
  },
};
