import { render, staticRenderFns } from "./ChooseStaff.vue?vue&type=template&id=35b06f94&scoped=true"
import script from "./ChooseStaff.vue?vue&type=script&lang=ts"
export * from "./ChooseStaff.vue?vue&type=script&lang=ts"
import style0 from "./ChooseStaff.vue?vue&type=style&index=0&id=35b06f94&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35b06f94",
  null
  
)

export default component.exports