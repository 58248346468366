<script lang="ts">
//@ts-nocheck
import Vue from "vue";
import Component from "vue-class-component";
import { Getter, Action } from "vuex-class";
import { IUserDTO, IStaffMemberBookingDTO } from "@shared/types";
import { actionStringStaff, mutationStringStaff } from "@/store/staff";
import { Prop } from "vue-property-decorator";
import { formatDate, FormatSpaceType } from "@/types/formatDateHelper";

@Component({
  name: "choose-staff",
})
export default class ChooseStaff extends Vue {
  @Getter public userToBook: IUserDTO;
  @Getter public selectedStaffMember: IStaffMemberBookingDTO | null;
  @Getter public staffMemberCount: number;

  @Prop({ default: null }) selectedServices: any;

  public isLoading: boolean = false;

  @Action(actionStringStaff.GET_STAFF_MEMBERS) getStaffMembers: (params: {
    adminId: string;
    serviceBookings: any[];
  }) => Promise<IStaffMemberBookingDTO[]>;
  @Action(actionStringStaff.GET_STAFF_COUNT) getStaffCount: (
    adminId: string
  ) => Promise<number>;

  public staffMembers: IStaffMemberBookingDTO[] = [];
  public anyStaffSelected: boolean = false;

  formatNextAvailable(date: Date): string {
    const formattedDate = formatDate(date, FormatSpaceType.DOT);
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");

    // Format time based on user preference
    let timeStr;
    if (this.userToBook.timeFormat === "12h") {
      const period = hours >= 12 ? "PM" : "AM";
      const displayHours = hours % 12 || 12; // Convert 0 to 12 for 12 AM
      timeStr = `${displayHours}:${minutes} ${period}`;
    } else {
      // 24h format (default)
      timeStr = `${hours.toString().padStart(2, "0")}:${minutes}`;
    }

    return `${formattedDate} ${timeStr}`;
  }

  get sortedStaffMembers(): IStaffMemberBookingDTO[] {
    return [...this.staffMembers].sort((a, b) => {
      // If one is available and the other isn't, available comes first
      if (a.nextAvailable && !b.nextAvailable) return -1;
      if (!a.nextAvailable && b.nextAvailable) return 1;

      // If both are available, sort by next available time
      if (a.nextAvailable && b.nextAvailable) {
        return (
          new Date(a.nextAvailable).getTime() -
          new Date(b.nextAvailable).getTime()
        );
      }

      // If both are unavailable, maintain original order
      return 0;
    });
  }

  async mounted() {
    await this.getStaffCount(this.userToBook.userId);

    this.isLoading = true;
    this.staffMembers = await this.getStaffMembers({
      adminId: this.userToBook.userId,
      serviceBookings: this.selectedServices,
    });
    this.isLoading = false;
    this.$store.commit(
      "setStepperTitle",
      this.$t(this.$ts.bookingStepper.selectStaff).toString()
    );
  }

  onSelectAnyStaff() {
    this.anyStaffSelected = true;
    this.$store.commit(mutationStringStaff.SET_SELECTED_STAFF, null);
    this.$emit("onselect", null);
  }

  onSelectStaff(staff: IStaffMemberBookingDTO) {
    if (!staff.nextAvailable) return; // Prevent selecting unavailable staff
    this.$store.commit(mutationStringStaff.SET_SELECTED_STAFF, staff);
    this.$emit("onselect", staff);
  }
}
</script>

<template>
  <v-container fluid class="pt-0">
    <v-col style="background-color: #f5f7fa" cols="auto" justify="center">
      <v-row class="flex-lg-row" justify="center">
        <v-col lg="10" md="12" sm="12" class="choose-staff-col pt-0">
          <v-card class="choose-staff-card rounded-lg elevation-1 pa-4" flat>
            <h2 class="text-h5 font-weight-medium mb-6">{{ $t($ts.chooseStaff.title) }}</h2>
            
            <!-- Any Staff Option -->
            <v-card
              class="staff-card mb-5 transition-swing rounded-lg border overflow-hidden"
              :class="{ 'staff-card-selected': anyStaffSelected }"
              @click="onSelectAnyStaff"
              elevation="0"
              hover
            >
              <div class="d-flex flex-nowrap align-center">
                <div class="staff-indicator" :class="{ 'indicator-selected': anyStaffSelected }"></div>
                <v-col cols="auto" class="staff-avatar-col pa-4">
                  <div class="staff-avatar-wrapper">
                    <v-avatar size="80" class="staff-avatar bg-grey-lighten-4 square-avatar">
                      <v-icon size="40" color="grey darken-1">
                        mdi-account-group
                      </v-icon>
                    </v-avatar>
                  </div>
                </v-col>
                <v-col class="staff-info-col">
                  <v-card-title class="any-staff-title py-2 font-weight-bold text-truncate">
                    {{ $t($ts.chooseStaff.anyStaffMember) }}
                  </v-card-title>
                  <v-card-subtitle class="pb-2 text-wrap">
                    {{ $t($ts.chooseStaff.anyStaffMemberSubtitle) }}
                  </v-card-subtitle>
                </v-col>
              </div>
            </v-card>

            <!-- Loading Skeletons -->
            <v-row v-if="isLoading">
              <v-col
                v-for="n in staffMemberCount"
                :key="n"
                cols="12"
                sm="6"
                md="4"
                lg="4"
                xl="3"
                class="pa-2"
              >
                <v-card class="staff-card rounded-lg border overflow-hidden" elevation="0">
                  <div class="d-flex flex-nowrap align-center">
                    <div class="staff-indicator"></div>
                    <v-col cols="auto" class="staff-avatar-col pa-4">
                      <v-skeleton-loader
                        type="avatar"
                        width="80"
                        height="80"
                        class="rounded-lg square-skeleton"
                      ></v-skeleton-loader>
                    </v-col>
                    <v-col class="staff-info-col">
                      <v-card-title class="py-2">
                        <v-skeleton-loader type="text"></v-skeleton-loader>
                      </v-card-title>
                      <v-card-subtitle class="pb-2">
                        <v-skeleton-loader
                          type="text"
                          width="60%"
                        ></v-skeleton-loader>
                      </v-card-subtitle>
                    </v-col>
                  </div>
                </v-card>
              </v-col>
            </v-row>

            <!-- Staff Members Grid -->
            <v-row v-else class="staff-grid">
              <v-col
                v-for="staff in sortedStaffMembers"
                :key="staff.id"
                cols="12"
                sm="6"
                md="4"
                lg="4"
                xl="3"
                class="pa-2"
              >
                <v-card
                  class="staff-card transition-swing rounded-lg border overflow-hidden"
                  :class="{
                    'staff-card-selected': selectedStaffMember?.id === staff.id,
                    'staff-card-unavailable': !staff.nextAvailable,
                  }"
                  @click="onSelectStaff(staff)"
                  elevation="0"
                  :hover="staff.nextAvailable"
                >
                  <div class="d-flex flex-nowrap align-center">
                    <div class="staff-indicator" 
                         :class="{ 'indicator-selected': selectedStaffMember?.id === staff.id }"></div>
                    <v-col cols="auto" class="staff-avatar-col pa-4">
                      <div class="staff-avatar-wrapper">
                        <v-avatar size="80" class="staff-avatar bg-grey-lighten-4 square-avatar">
                          <v-img
                            v-if="staff.profilePictureUrl"
                            :src="staff.profilePictureUrl"
                            :alt="staff.fullName"
                            cover
                          ></v-img>
                          <v-icon
                            v-else
                            size="40"
                            :color="
                              staff.nextAvailable
                                ? 'grey darken-1'
                                : 'grey lighten-1'
                            "
                          >
                            mdi-account
                          </v-icon>
                        </v-avatar>
                      </div>
                    </v-col>
                    <v-col class="staff-info-col">
                      <v-card-title
                        :class="{
                          'staff-name': true,
                          'staff-name-available': staff.nextAvailable,
                          'staff-name-unavailable': !staff.nextAvailable,
                        }"
                        class="py-2 text-subtitle-1 font-weight-bold text-truncate"
                      >
                        {{ staff.fullName }}
                      </v-card-title>
                      <v-card-subtitle class="pb-2 text-wrap">
                        <div v-if="staff.nextAvailable" class="next-available d-flex align-center flex-wrap">
                          <v-icon small class="mr-1 text-success">mdi-clock-outline</v-icon>
                          <span class="availability-label">{{ $t($ts.chooseStaff.nextAvailable) }}:</span>
                          <span class="availability-time">{{ formatNextAvailable(new Date(staff.nextAvailable)) }}</span>
                        </div>
                        <div v-else class="unavailable-text d-flex align-center">
                          <v-icon small class="mr-1 text-grey">mdi-clock-alert-outline</v-icon>
                          {{ $t($ts.chooseStaff.currentlyUnavailable) }}
                        </div>
                      </v-card-subtitle>
                    </v-col>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</template>

<style scoped>
.choose-staff-card {
  background-color: white;
  border-radius: 12px;
}

.staff-card {
  position: relative;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.05) !important;
  background: rgba(92, 107, 192, 0.03);
  min-height: 120px;
}

.staff-card:hover:not(.staff-card-unavailable) {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(92, 107, 192, 0.1) !important;
  border-color: rgba(92, 107, 192, 0.2) !important;
  background: rgba(92, 107, 192, 0.05);
}

.staff-card-selected {
  border-color: #5c6bc0 !important;
  background-color: rgba(92, 107, 192, 0.08);
}

.staff-indicator {
  width: 4px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: transparent;
  transition: background-color 0.3s ease;
}

.indicator-selected {
  background-color: #5c6bc0;
}

.staff-card-unavailable {
  opacity: 0.7;
  background-color: #fafafa;
}

.staff-name {
  font-weight: 600;
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.staff-name-unavailable {
  color: rgba(0, 0, 0, 0.6);
}

.next-available {
  color: #4caf50;
  font-size: 0.85rem;
}

.unavailable-text {
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.85rem;
}

.staff-avatar-wrapper {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  min-width: 80px;
  min-height: 80px;
}

.staff-avatar {
  border: 2px solid rgba(92, 107, 192, 0.2);
  box-shadow: 0 2px 8px rgba(92, 107, 192, 0.1);
  width: 80px !important;
  height: 80px !important;
  min-width: 80px !important;
  min-height: 80px !important;
}

.square-avatar {
  aspect-ratio: 1/1 !important;
  object-fit: cover !important;
}

.square-skeleton {
  aspect-ratio: 1/1 !important;
  min-width: 80px !important;
  min-height: 80px !important;
}

.staff-grid {
  margin-top: 8px;
}

.staff-info-col {
  min-width: 0; /* Allows text truncation to work properly */
  padding-right: 16px;
}

.availability-label {
  margin-right: 4px;
  white-space: nowrap;
}

.availability-time {
  white-space: nowrap;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .staff-card {
    min-height: 110px;
  }
  
  .staff-avatar-wrapper {
    width: 64px;
    height: 64px;
    min-width: 64px;
    min-height: 64px;
  }
  
  .staff-avatar {
    width: 64px !important;
    height: 64px !important;
    min-width: 64px !important;
    min-height: 64px !important;
    max-width: 64px !important;
    max-height: 64px !important;
  }
  
  .staff-avatar-col {
    padding: 12px !important;
  }
  
  .staff-name {
    font-size: 0.95rem;
  }
  
  .next-available, .unavailable-text {
    font-size: 0.8rem;
  }

  .square-skeleton {
    min-width: 64px !important;
    min-height: 64px !important;
    width: 64px !important;
    height: 64px !important;
  }
}

/* For animations */
.transition-swing {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.text-wrap {
  white-space: normal !important;
  word-break: break-word;
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>