<script lang="ts">
//@ts-nocheck
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { DialogActions, IDialog } from "../../store/dialog";
import {
  actionStringAppointmentType,
  getterStringAppointmentType,
  IappointmentType,
} from "../../store/appointments/appointmentTypes";
import { Action, Getter } from "vuex-class";
import { IUser } from "../../store/user";
import ServiceItem from "../../components/user/ServiceItem.vue";
import { IUserDTO } from "@shared/types";
import { formatCurrency } from "../../Utilities/currencyHelper";
//* Component definition ************************************************************

@Component({
  name: "select-service",
  components: { ServiceItem },
})

//* Class ***************************************************************************
export default class SelectService extends Vue {
  @Getter(getterStringAppointmentType.APPOINTMENT_TYPE)
  appointmentType: IappointmentType[];
  @Action(actionStringAppointmentType.GET_APPOINTMENT_TYPES_AS_CUSTOMER)
  getAppointmentTypesAsCustomer: (
    adminId: string
  ) => Promise<IappointmentType[]>;
  @Action(DialogActions.AddToDialogQueue) addToDialogQueue: (
    dialog: IDialog
  ) => void;
  @Getter public userToBook: IUserDTO;

  @Prop({ default: null }) highlightedService;

  public services: IappointmentType[] = [];

  public selectedServices: IappointmentType[] = [];

  public searchQuery: string = "";

  public hoveringService: IappointmentType = null;
  showSheet = false;
  private details: any = null;

  get isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }
  async mounted() {
    this.services = await this.getAppointmentTypesAsCustomer(
      this.userToBook.userId
    );
    
    // Debug the services data structure
    console.log("All services:", this.services);
    console.log("With category:", this.appointmentTypeWithCategory);
    console.log("Without category:", this.appointmentTypeWithoutCategory);

    if (this.userToBook.multipleServices) {
      this.$store.commit(
        "setStepperTitle",
        this.$t(this.$ts.bookingStepper.selectServices).toString()
      );
    } else {
      this.$store.commit(
        "setStepperTitle",
        this.$t(this.$ts.bookingStepper.selectServices).toString()
      );
    }
  }

  get allAppointmentsCount() {
    return (
      this.appointmentTypeWithCategory.length +
      this.appointmentTypeWithoutCategory.length
    );
  }
  
  get filteredAppointmentTypeWithCategory() {
    if (!this.userToBook.customerCanSearchForAppointments || !this.searchQuery) {
      return this.appointmentTypeWithCategory;
    }
    
    const searchLower = this.searchQuery.toLowerCase();
    
    return this.appointmentTypeWithCategory.filter(category => {
      // Check if the category title matches the search query
      if (category.title && category.title.toLowerCase().includes(searchLower)) {
        return true;
      }
      
      // Check if any service in the category matches the search query
      if (category.services && category.services.length > 0) {
        const hasMatchingService = category.services.some(service => 
          service && service.title && service.title.toLowerCase().includes(searchLower)
        );
        return hasMatchingService;
      }
      
      return false;
    });
  }
  
  get filteredAppointmentTypeWithoutCategory() {
    if (!this.userToBook.customerCanSearchForAppointments || !this.searchQuery) {
      return this.appointmentTypeWithoutCategory;
    }
    
    const searchLower = this.searchQuery.toLowerCase();
    
    // Debug the uncategorized services
    console.log("Uncategorized services:", this.appointmentTypeWithoutCategory);
    
    return this.appointmentTypeWithoutCategory.filter(service => {
      // Check if the service has a title we can search in
      if (!service || !service.title) {
        // For services without a title, check if they have a name property instead
        return service && service.name && 
               service.name.toLowerCase().includes(searchLower);
      }
      
      // For services with a title, check if it matches the search
      return service.title.toLowerCase().includes(searchLower);
    });
  }
  
  get appointmentTypeWithCategory() {
    if (typeof this.appointmentType.filter == "function") {
      return this.appointmentType.filter((x) => {
        return x.title != "" && x.title != null;
      });
    } else {
      return [];
    }
  }
  get appointmentTypeWithoutCategory() {
    if (typeof this.appointmentType.filter == "function") {
      // Make sure we're correctly identifying services without categories
      return this.appointmentType.filter((x) => {
        return x.title === "" || x.title === null || x.title === undefined;
      });
    } else {
      return [];
    }
  }

  sum(total, num) {
    return total + num;
  }

  get totalDuration() {
    if (this.selectedServices == null) {
      return 0;
    }
    let durations = this.selectedServices.map((x) => x.durationInMinutes);
    if (durations.length == 0) {
      return 0;
    }
    return durations.reduce(this.sum);
  }

  get totalPrice() {
    if (this.selectedServices == null) {
      return 0;
    }
    let prices = this.selectedServices.map((x) => x.price);
    if (prices.length == 0) {
      return 0;
    }
    return formatCurrency(prices.reduce(this.sum));
  }

  onSelect(service: IappointmentType) {
    setTimeout(() => {
      if (this.userToBook.multipleServices == false) {
        let services = [];
        services.push(service); //Array with 1 service
        this.$emit("onselect", services);
      }
    }, 1);
  }
  onContinueWithMultipleServices() {
    this.$emit("onselect", this.selectedServices);
  }

  getCardColor(service: IappointmentType) {
    if (this.isHighlighted(service)) {
      return "#ccccc";
    } else {
      return "#EFF0F1";
    }
  }

  showInfo(text: string) {
    let dialog: IDialog = {
      text: text,
      header: this.$t(this.$ts.dialog.description).toString(),
    };
    this.addToDialogQueue(dialog);
  }

  isHighlighted(service: IappointmentType) {
    return (
      this.highlightedService != null && this.highlightedService == service.id
    );
  }

  getImgUrl(pet) {
    if (pet == null) {
      var images = require.context("../../../public/img/", false, /\.png$/);
      return images("./" + "default_service_img" + ".png");
    } else {
      var images = require.context("../../../public/img/", false, /\.png$/);
      return images("./" + pet + ".png");
    }
  }

  updateDetails() {
    let serviceBookingsDetails: any = this.selectedServices.map((x: any) => {
      let children = x.children.filter((c) => {
        return c.selected;
      });
      return { service: x, children: children };
    });

    this.details = serviceBookingsDetails;
  }
  check(event) {
    this.selectedServices.push(event);
    if (this.isMobile) {
      this.showSheet = true;
    } else {
      this.showSheet = false;
    }
    this.updateDetails();
  }
  uncheck(event) {
    let i = this.selectedServices.find((x) => {
      return x.id === event.id;
    });
    let index = this.selectedServices.indexOf(i);
    this.selectedServices.splice(index, 1);
    this.updateDetails();
  }

  convertMinsToHrsMins(mins) {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    //@ts-ignore
    h = h < 10 ? "" + h : h;
    //@ts-ignore
    m = m < 10 ? "" + m : m;
    if (h == 1 && m == 0) {
      return "60 min";
    }
    if (h == 0) {
      return m + "min";
    }
    if (m == 0) {
      return h + "t";
    }
    return `${h}t ${m}m`;
  }

  getTitle(service): string {
    let title = service.service.title;
    let index = 0;
    if (service.children && service.children.length > 0) {
      title += "(+ ";
      service.children.forEach((x) => {
        if (index != 0) {
          title += ", ";
        }
        title += x.title;
        index++;
      });
      title += ")";
    }
    return title;
  }

  getPrice(service): string {
    //so a service can either be just a service here, or a service with children

    let price = 0;
    if (service.service) {
      price = service.service.price;
    } else {
      price = service.price;
    }

    if (service.children) {
      service.children.forEach((x) => {
        price += x.price;
      });
    }

    return formatCurrency(price);
  }
}
</script>

<template>
  <v-container fluid class="pt-0">
    <v-col style="background-color: #ececf3" cols="auto" justify="center">
      <v-row class="flex-lg-row" justify="center">
        <v-col
          class="hidden-sm-and-up"
          v-if="userToBook.showInformationBox"
          cols="auto"
        >
          <v-card flat class="pa-10">
            <v-card-text class="pa-10">
              <div v-html="userToBook.informationBoxText"></div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          v-if="allAppointmentsCount > 0"
          lg="6"
          md="12"
          sm="12"
          class="choose-service-col pt-0"
        >
          <v-card class="choose-service-card" flat>
            <!-- Search Bar -->
            <v-card-text v-if="userToBook.customerCanSearchForAppointments" class="pb-0">
              <v-text-field
                v-model="searchQuery"
                :label="$t($ts.chooseService.searchServices)"
                :placeholder="$t($ts.chooseService.searchPlaceholder)"
                prepend-inner-icon="mdi-magnify"
                clearable
                outlined
                dense
                hide-details
                class="search-field"
              ></v-text-field>
            </v-card-text>
            
            <v-list two-line subheader style="padding-bottom: 0px !important">
              <v-divider></v-divider>

              <!-- Display categorized services -->
              <v-expansion-panels v-if="filteredAppointmentTypeWithCategory.length > 0">
                <v-expansion-panel
                  v-for="(category, i) in filteredAppointmentTypeWithCategory"
                  :key="'cat-'+i"
                >
                  <v-expansion-panel-header>
                    {{ category.title }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <service-item
                      @check="check"
                      @uncheck="uncheck"
                      :selectedServices="selectedServices"
                      @onselect="onSelect"
                      :category="category"
                    ></service-item>
                    <v-divider></v-divider>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <!-- Display uncategorized services -->
              <div v-if="filteredAppointmentTypeWithoutCategory.length > 0">
                <div v-for="(service, i) in filteredAppointmentTypeWithoutCategory" :key="'uncat-'+i">
                  <service-item
                    @onselect="onSelect"
                    @check="check"
                    @uncheck="uncheck"
                    :selectedServices="selectedServices"
                    :category="service"
                  ></service-item>
                  <v-divider></v-divider>
                </div>
              </div>
              
              <!-- No results message -->
              <div v-if="searchQuery && filteredAppointmentTypeWithCategory.length === 0 && filteredAppointmentTypeWithoutCategory.length === 0" class="pa-4 text-center">
                No services found matching "{{ searchQuery }}"
              </div>
            </v-list>
          </v-card>
        </v-col>

        <v-col
          class="hidden-xs-only"
          v-if="userToBook.showInformationBox || userToBook.multipleServices"
          cols="auto"
        >
          <v-card v-if="userToBook.showInformationBox" flat class="pa-10">
            <v-card-text class="pa-10">
              <div v-html="userToBook.informationBoxText"></div>
            </v-card-text>
          </v-card>

          <v-card v-if="userToBook.multipleServices" class="fixed-on-large">
            <v-card-title>
              {{ $t($ts.chooseService.selectedServices) }}
            </v-card-title>
            <v-card-text>
              <div v-for="service in selectedServices">
                <span>{{ service.title }}</span>
                <span v-if="userToBook.showServicePrice">
                  | {{ $t($ts.currency, { amount: getPrice(service) }) }}</span
                >
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                :disabled="selectedServices.length === 0"
                @click="onContinueWithMultipleServices"
                color="success"
              >
                {{ $t($ts.chooseService.continue) }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <div v-if="isMobile" class="hidden-sm-and-up">
      <div
        tabindex="-1"
        class="v-dialog__content v-dialog__content--active"
        style="z-index: 202; height: auto; bottom: 0"
        v-if="showSheet"
      >
        <div
          class="v-dialog v-bottom-sheet v-bottom-sheet--inset v-dialog--active v-dialog--persistent"
        >
          <v-card v-if="userToBook.showInformationBox" flat class="pa-10">
            <v-card-text class="pa-10">
              <div v-html="userToBook.informationBoxText"></div>
            </v-card-text>
          </v-card>

          <v-card v-if="userToBook.multipleServices">
            <v-card-title>
              {{ $t($ts.chooseService.selectedServices) }}
            </v-card-title>
            <v-card-text class="pt-5">
              <ul v-for="service in details">
                <li>
                  <span> {{ getTitle(service) }}</span>
                  <span v-if="userToBook.showServicePrice">
                    ● {{ $t("currency", { amount: getPrice(service) }) }}</span
                  >
                </li>
              </ul>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer> </v-spacer>
              <v-btn
                :disabled="selectedServices.length === 0"
                @click="onContinueWithMultipleServices"
                color="success"
              >
                {{ $t($ts.chooseService.continue) }}
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </div>
    </div>
  </v-container>
</template>

<style scoped lang="scss">
.v-item-group {
  background: #ececf3;
}

.search-field {
  margin-bottom: 16px;
}
</style>

<style lang="scss">
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

.v-list-item--link {
  @media (max-width: 600px) {
    padding: 0 !important;
  }
}
.v-list-item__subtitle,
.v-list-item__title {
  white-space: normal !important;
}
.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
.choose-service-col {
  @media (min-width: 1025px) {
    max-width: 75%;
  }
}
.fixed-on-large {
  @media (min-width: 1025px) {
    position: fixed;
  }
}
.choose-service-card {
  @media (min-width: 1025px) {
    min-width: 600px;
  }
}
.serviceDescription {
  font-size: 16px;
}
.serviceDetail {
  margin-left: 5px;
  font-size: 16px;
}
</style>
