import { TranslationSchema } from "./translation.interface";

export const es: TranslationSchema = {
  currency: "${amount}",
  calendar: {
    noServiceDialogMessage: "Actualmente, ningún proveedor ofrece este servicio, por favor seleccione otro.",
    selectAnotherService: "Seleccionar otro servicio",
    availableHoursLabel: "Disponibles ",
    hoursOnDateLabel: "Horas el ",
    monday: "Lunes",
    tuesday: "Martes",
    wednesday: "Miércoles",
    thursday: "Jueves",
    friday: "Viernes",
    saturday: "Sábado",
    sunday: "Domingo",
    january: "Enero",
    february: "Febrero",
    march: "Marzo",
    april: "Abril",
    may: "Mayo",
    june: "Junio",
    july: "Julio",
    august: "Agosto",
    september: "Septiembre",
    october: "Octubre",
    november: "Noviembre",
    december: "Diciembre",
  },
  chooseStaff: {
    anyStaffMember: "Cualquier Personal Disponible",
    anyStaffMemberSubtitle: "Reservar con el primer personal disponible",
    nextAvailable: "Próxima Disponibilidad",
    currentlyUnavailable: "Actualmente No Disponible",
  },
  hourList: {
    noAvailableHours: "No hay horas disponibles para este día",
    findFirstAvailableHour: "Buscar primera hora disponible",
  },
  datePicker: {
    cancel: "Cancelar",
    ok: "Aceptar",
  },
  bookingForm: {
    selectDepartment: "Seleccionar departamento",
    nameRequired: "El nombre es obligatorio",
    addressRequired: "La dirección es obligatoria",
    postalCodeRequired: "El código postal es obligatorio",
    emailRequired: "El correo electrónico es obligatorio",
    emailValid: "El correo electrónico debe ser válido",
    phoneRequired: "El número de teléfono es obligatorio",
    phoneValid: "El número de teléfono debe ser válido",
    passwordRequired: "La contraseña es obligatoria",
    passwordLength: "La contraseña debe tener al menos 5 caracteres",
    service: "Servicio",
    description: "Descripción",
    time: "Hora",
    total: "Total",
    totalDuration: "Total duración",
    selectService: "Por favor seleccione un servicio.",
    checkboxTooltipBoth: "Marque las casillas para recibir confirmación y recordatorio para la cita",
    checkboxTooltipReminder: "Marque la casilla para recibir un recordatorio para la cita",
    checkboxTooltipReceipt: "Marque la casilla para recibir confirmación de la cita",
    emailReminder: "Quiero un recordatorio por correo electrónico",
    emailReceipt: "Quiero confirmación por correo electrónico",
    smsReminder: "Quiero un recordatorio por SMS",
    smsReceipt: "Quiero confirmación por SMS",
    whatsappReminder: "Quiero un recordatorio por WhatsApp",
    whatsappReceipt: "Quiero confirmación por WhatsApp",
    newsletterSignup: "Regístrame en el boletín",
    bookAppointment: "Reservar cita",
    agreeWithConsent: "Acepto las normas de cancelación y cambio",
    addComment: "Agregar un comentario",
    phoneLength: "El número de teléfono debe tener {length} dígitos",
    invalidCountryCode: "Código de país inválido",
  },
  bookingStepper: {
    selectService: "Seleccionar Servicio",
    selectServices: "Seleccionar Servicios",
    selectStaff: "Seleccionar Personal",
    selectTime: "Seleccionar Hora",
    yourInformation: "Tu Información",
    confirm: "Confirmar",
    receipt: "Recibo",
    back: "Atrás",
    thankYou: "¡Gracias por tu reserva!",
    receiptEmail: "Se ha enviado un recibo a tu correo electrónico.",
    reminderEmail: "Se enviará un recordatorio a tu correo electrónico.",
    receiptSMS: "Se ha enviado un recibo a tu teléfono.",
    reminderSMS: "Se enviará un recordatorio a tu teléfono.",
    booking: "Reserva",
  },
  chooseService: {
    selectedServices: "Servicios seleccionados",
    continue: "Continuar",
    searchServices: "Buscar servicios",
    searchPlaceholder: "Escribe para buscar servicios...",
  },
  dialog: {
    description: "Descripción",
    cancel: "Cancelar",
    confirm: "Confirmar",
    yes: "Sí",
    no: "No",
  },
  customerAccount: {
    phoneRequired: "El número de teléfono es obligatorio",
    phoneValid: "El número de teléfono debe ser válido",
    codeRequired: "El código es obligatorio",
    codeValid: "El código debe ser válido",
    myPage: "Mi Página",
    invalidCode: "Código inválido, por favor intente de nuevo",
    confirmCancel: "¿Está seguro de que desea cancelar esta cita?",
    appointmentCancelled: "Su cita ha sido cancelada.",
    cancelLimit: "Solo puede cancelar con {hours} horas de anticipación.",
    appointmentChanged: "¡Ha cambiado su cita!",
    changeTime: "¿Desea cambiar la hora a {date} {time}?",
    phoneNumber: "Su número de teléfono",
    smsCode: "Código de SMS",
    login: "Iniciar sesión",
    yourAppointments: "Sus Citas",
    cancellationPolicy:
      "La cancelación/cambio de cita debe hacerse al menos {hours} horas antes de la hora programada.",
    service: "Servicio",
    time: "Hora",
    move: "Mover",
    cancel: "Cancelar",
    bookNewAppointment: "Reservar nueva cita",
    editInformation: "Editar su información",
    closeEditing: "Cerrar edición",
    editUser: "Editar usuario",
    save: "Guardar",
    name: "Su nombre",
    phoneNumberOptional: "Número de teléfono (Opcional)",
    email: "Correo electrónico",
    emailOptional: "Correo electrónico (Opcional)",
    address: "Dirección",
    city: "Ciudad",
    postalCode: "Código postal",
    ssn: "Número de Seguro Social",
    birthdate: "Fecha de nacimiento",
    gender: "Género",
    male: "Masculino",
    female: "Femenino",
    password: "Contraseña",
    sendNewPassword: "Enviar nueva contraseña",
    enterCodeBelow: "Ingrese el código abajo",
    passwordChanged: "Su contraseña ha sido cambiada",
    next: "Siguiente",
    cityRequired: "La ciudad es obligatoria",
    countryCode: "Código de país",
    countryCodeRequired: "El código de país es obligatorio",
    birthdateRequired: "La fecha de nacimiento es obligatoria",
    genderRequired: "El género es obligatorio",
    ssnRequired: "El número de seguro social es obligatorio",
  },
  serviceItem: {
    noAvailableTimes: "No hay horarios disponibles para este servicio.",
    today: "hoy",
    tomorrow: "mañana",
    dayAfterTomorrow: "pasado mañana",
    selectAdditionalServices: "Seleccionar servicios adicionales",
    continue: "Continuar",
    confirm: "Confirmar",
    firstAvailable: "Primero disponible",
    hour: "hora",
    hours: "horas",
    min: "min",
    variablePriceInfo:
      "El precio varía según los detalles del servicio. El personal proporcionará el precio exacto al llegar.",
    unavailableOnlineBooking: "Este servicio no está disponible para reserva en línea",
    useWhatsApp: "Por favor, use WhatsApp para reservar este servicio",
    whatsAppMessage: "¡Hola! Me gustaría reservar",
    whatsAppDateTime: "para",
  },
  mainApp: {
    myPage: "Mi Página",
    logout: "Cerrar sesión",
    leaveBooking: "¿Desea abandonar la reserva?",
  },
  receiptPage: {
    eventTitle: "Cita en {companyName}",
    addToCalendar: "Añadir a su calendario",
    bookNewTime: "Reservar nueva hora",
    goToMyPage: "Ir a mi página",
  },
  payment: {
    title: "Pago",
    description: "Monto a pagar: {amount} {currency}",
    noProviderConfigured:
      "No se ha configurado ningún proveedor de pago. Por favor, contacte al proveedor del servicio.",
  },
};
